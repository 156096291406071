<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h2 class="modal-card-title">{{ $t("auth.login_title") }}</h2>
      <p class="modal-card-subtitle has-text-centered">
        {{ $t("auth.facebook_consent_subtitle") }}
      </p>
      <button class="delete" type="button" @click="$parent.close"></button>
    </header>

    <section class="modal-card-body">
      <consent-form :showProgress="isRequesting" :isAppleSignIn="isAppleSignIn" @submit="onSubmit"></consent-form>
      <div v-if="error" class="mt-2 is-size-4 has-text-weight-bold has-text-danger">
        <div class="has-text-centered">
          {{ error | pluckError }}
        </div>
      </div>
    </section>

    <footer class="modal-card-foot"></footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import RedirectMixin from "@/web/mixins/RedirectMixin";
import ConsentForm from "@/web/components/forms/ConsentForm";
import Constants from "@/web/constants";

export default {
  mixins: [RequestMixin, RedirectMixin],

  name: "GdprConsentModal",

  components: {
    ConsentForm,
  },

  requests: {
    async facebookLogin() {
      this.openAuthLoadingModal();
      await this.loginViaFacebook(true);
      // logEvent(getAnalytics(), Constants.ANALYTICS_EVENTS.USER_REGISTERED, {
      //   [Constants.ANALYTICS_PARAMETERS.AUTHORIZATION_TYPE]: Constants.ANALYTICS_AUTHORIZATION_TYPES.FACEBOOK,
      // });
      this.afterLogin();
    },

    async googleLogin() {
      this.openAuthLoadingModal();
      await this.loginViaGoogle(true);
      // logEvent(getAnalytics(), Constants.ANALYTICS_EVENTS.USER_REGISTERED, {
      //   [Constants.ANALYTICS_PARAMETERS.AUTHORIZATION_TYPE]: Constants.ANALYTICS_AUTHORIZATION_TYPES.GOOGLE,
      // });
      this.afterLogin();
    },

    async appleLogin(formData) {
      this.openAuthLoadingModal();
      await this.loginViaApple({
        gdprConsent: true,
        firstName: formData.firstName,
        lastName: formData.lastName,
      });
      // logEvent(getAnalytics(), Constants.ANALYTICS_EVENTS.USER_REGISTERED, {
      //   [Constants.ANALYTICS_PARAMETERS.AUTHORIZATION_TYPE]: Constants.ANALYTICS_AUTHORIZATION_TYPES.APPLE,
      // });
      this.afterLogin();
    },
  },

  methods: {
    ...mapActions(["loginViaFacebook", "loginViaGoogle", "loginViaApple"]),

    afterLogin() {
      this.nextRedirect({ name: Constants.ROUTE_MAIN_INTRODUCTION });
      this.$parent.close();
    },

    openAuthLoadingModal() {
      this.$root.openAuthLoadingModal();
    },

    onSubmit(formData) {
      if (this.accessToken) {
        this.facebookLogin();
      } else if (this.googleToken) {
        this.googleLogin();
      } else if (this.appleToken) {
        this.appleLogin(formData);
      } else {
        this.$parent.close();
      }
    },
  },

  computed: {
    ...mapState("facebook", ["accessToken"]),
    ...mapState("apple", ["appleToken"]),
    ...mapState("google", ["googleToken"]),

    isAppleSignIn() {
      return !!this.appleToken;
    },
  },
};
</script>
