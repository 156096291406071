<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ $t("common.error_modal_title") }}
      </h1>
      <div class="modal-close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <div class="size-6 subtitle has-text-centered">
        {{ error }}
      </div>
      <div>
        <button class="button is-fullwidth is-danger" style="margin-top: 1rem" @click="$parent.close()">
          {{ $t("common.ok") }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";

export default {
  name: "ErrorModal",

  props: {
    errorMessage: {
      type: String,
      required: true,
    },
    errorCode: {
      type: String,
      default: null,
    },
  },

  components: {
    CloseIcon,
  },

  computed: {
    error() {
      if (this.$i18n.te(this.errorKey)) {
        return this.$i18n.t(this.errorKey);
      }
      return this.errorMessage;
    },

    errorKey() {
      if (!this.errorCode) {
        return null;
      }
      const code = this.errorCode.replace(/\./g, "_");
      return `custom.error_${code}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}
</style>
