import authService from "@/web/services/auth";
import createStorage from "@/web/services/storage";
import Constants from "@/web/constants";
import ComponentModel from "@/web/store/models/ComponentModel";

const tokenStorage = createStorage("auth-token");

export const state = () => ({
  token: null,
  refreshToken: null,
  expirationDate: null,
  storageListener: null,
  joined: false,
  status: null,
  emailTakenModalStatus: false,
  authLoadingStatus: null,
});

export const mutations = {
  setToken(state, { token, refreshToken, expirationDate }) {
    state.refreshToken = refreshToken;
    state.token = token;
    state.expirationDate = expirationDate;
  },
  setJoined(state, joined) {
    state.joined = joined;
  },
  clearToken(state) {
    state.refreshToken = null;
    state.expirationDate = null;
    state.token = null;
    state.joined = false;
  },
  setEmailTakenModalStatus(state, status) {
    state.emailTakenModalStatus = status;
  },
  setAuthLoadingStatus(state, authLoadingStatus) {
    state.authLoadingStatus = authLoadingStatus;
  },
  setStorageListener(state, listener) {
    state.storageListener = listener;
  },
};

export const actions = {
  async login({ dispatch, commit }, { eventId, email, password }) {
    const tokenData = await authService.login(email, password);
    tokenStorage.store(eventId, tokenData);
    commit("setToken", tokenData);
    commit("setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
    await dispatch("joinToEvent", eventId);
  },

  async loginViaFacebook({ dispatch, commit }, { eventId, accessToken, gdprConsent }) {
    const tokenData = await authService.loginViaFacebook(accessToken, gdprConsent);
    tokenStorage.store(eventId, tokenData);
    commit("setToken", tokenData);
    await dispatch("facebook/logout", null, { root: true });
    commit("setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
    await dispatch("joinToEvent", eventId);
  },

  async loginViaGoogle({ dispatch, commit }, { eventId, googleToken, gdprConsent }) {
    const tokenData = await authService.loginViaGoogle(googleToken, gdprConsent);
    tokenStorage.store(eventId, tokenData);
    commit("setToken", tokenData);
    await dispatch("google/logout", null, { root: true });
    commit("setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
    await dispatch("joinToEvent", eventId);
  },

  async loginViaAppimxle({ dispatch, commit }, { eventId, appleToken, gdprConsent, firstName, lastName }) {
    const tokenData = await authService.loginViaApple(appleToken, gdprConsent, firstName, lastName);
    tokenStorage.store(eventId, tokenData);
    commit("setToken", tokenData);
    await dispatch("apple/logout", null, { root: true });
    commit("setAuthLoadingStatus", Constants.AUTH_LOADING_STATUSES.JOINING_EVENT);
    await dispatch("joinToEvent", eventId);
  },

  async loginWithMagicToken({ dispatch, commit }, { eventId, magicLinkToken }) {
    const tokenData = await authService.loginViaMagicLink(magicLinkToken);
    tokenStorage.store(eventId, tokenData);
    commit("setToken", tokenData);
    await dispatch("joinToEvent", eventId);
  },

  async register({ dispatch, commit }, { eventId, email, password, firstName, lastName, metaData }) {
    const tokenData = await authService.register(email, password, firstName, lastName, eventId, metaData);
    tokenStorage.store(eventId, tokenData);
    commit("setToken", tokenData);
  },

  async joinToEvent({ commit, state }, eventId) {
    const joined = await authService.joinToEvent(eventId);
    commit("setJoined", joined);
    if (!state.storageListener) {
      const listener = () => {
        const token = tokenStorage.retrieve(eventId);
        if (token) {
          // console.log(`token updated from storage ${token.refreshToken}`);
          commit("setToken", token);
        } else {
          // console.log(`tokens cleared`);
          commit("clearToken");
        }
      };
      window.addEventListener("storage", listener, false);
      commit("setStorageListener", listener);
    }
  },

  async logout({ state, commit }, eventId) {
    tokenStorage.clear(eventId);
    await authService.logout();
    commit("clearToken");
    if (state.storageListener) {
      window.removeEventListener("storage", state.storageListener, false);
      commit("setStorageListener", null);
    }
  },

  async updateUserPassword(context, { newPassword, newPasswordConfirmation }) {
    await authService.changePassword(newPassword, newPasswordConfirmation);
  },

  async updateUserPasswordWithToken(context, { token, newPassword, newPasswordConfirmation }) {
    await authService.resetPasswordWithToken(token, newPassword, newPasswordConfirmation);
  },

  async deleteAccount({ commit, dispatch }, eventId) {
    await authService.deleteAccount();
    tokenStorage.clear(eventId);
    commit("clearToken");
  },

  async reset(context, { email }) {
    await authService.reset(email);
  },

  async refreshToken({ commit, state }, eventId) {
    const token = tokenStorage.retrieve(eventId);
    const refreshToken = token && token.refreshToken;
    if (refreshToken && (typeof refreshToken === "string" || refreshToken instanceof String) && refreshToken.length > 7) {
      const persistent = actions.refreshToken;

      // Reuse previous promise (if any exists)
      if (persistent.promise) {
        return persistent.promise;
      }

      const promise = authService
        .refresh(refreshToken)
        .then(tokenData => {
          tokenStorage.store(eventId, tokenData);
          commit("setToken", tokenData);
        })
        .catch(err => {
          // console.log(`refreshToken: err catched`);
          // console.log(`refreshToken: start timeout ${new Date().toString()}`);
          return new Promise((resolve, reject) => {
            // console.log(`refreshToken: start timeout ${new Date().toString()}`);
            let wait = setTimeout(() => {
              clearTimeout(wait);
              resolve(true);
            }, 3000);
          }).then(result => {
            // console.log(`refreshToken: end timeout ${new Date().toString()}`);
            const latestToken = tokenStorage.retrieve(eventId);
            // console.log(`latestToken: ${new Date().toString()}`);
            if (latestToken && new Date(latestToken.expirationDate) > new Date(token.expirationDate)) {
              // console.log(`refreshToken: setUpdatedToken: ${latestToken.refreshToken}`);
              return commit("setToken", latestToken);
            }
            tokenStorage.clear(eventId);
            commit("clearToken");
            // console.log(`refreshToken: rethrow error, currentToken: ${token.expirationDate} latestToken: ${latestToken && latestToken.expirationDate}`);
            throw err;
          });
        })
        .finally(() => {
          persistent.promise = null;
        });

      persistent.promise = promise;
      return persistent.promise;
    } else {
      tokenStorage.clear(eventId);
      commit("clearToken");
      return Promise.resolve();
    }
  },

  async retrieveToken({ dispatch, commit }, eventId) {
    const tokenData = tokenStorage.retrieve(eventId);

    if (tokenData && tokenData.token) {
      commit("setToken", tokenData);
      // FIX: Refresh page on whitelist enabled event
      await dispatch("joinToEvent", eventId);
    }
  },

  async requestJoinToEvent(context, eventId) {
    await authService.requestJoinToEvent(eventId);
    return true;
  },

  async resendEmailConfirmation(context, email) {
    await authService.resendEmailConfirmation(email);
    return true;
  },

  async sendMagicLink(context, { email, eventId }) {
    await authService.sendMagicLink(email, eventId);
  },
};

export const getters = {
  isUnauthorized(state) {
    return !state.token;
  },

  isAuthorized(state) {
    return !!state.token;
  },

  isJoined(state, getters) {
    return getters.isAuthorized && state.joined;
  },

  isAdmin(state, getters) {
    return ComponentModel.query().where("component_name", Constants.ADMIN_COMPONENT_TYPE).all().length > 0;
  },
  latestToken(state, getters, rootState, rootGetters) {
    if (state.token) {
      const eventId = rootState.eventId;
      return tokenStorage.retrieve(eventId);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
