import { Model } from "@vuex-orm/core";

export default class AttachmentModel extends Model {
  static entity = "attachments";

  static fields() {
    return {
      id: this.attr(null),
      file_url: this.string(""),
      file_name: this.string(""),
      file_thumbnail_200_url: this.string(""),
      file_thumbnail_750_url: this.string(""),
      meta: this.attr(null),
    };
  }
}
