import { Model } from "@vuex-orm/core";
import UserModel from "@/web/store/models/UserModel";
import InboxMessageModel from "@/web/store/models/InboxMessageModel";

export default class InboxThreadModel extends Model {
  static entity = "inbox_threads";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(null).nullable(),
      message_id: this.attr(""),
      message: this.belongsTo(InboxMessageModel, "message_id"),
      user_ids: this.attr([]),
      users: this.hasManyBy(UserModel, "user_ids"),
      created_at: this.string(""),
      updated_at: this.string(""),
      read_at: this.string("").nullable(),
    };
  }
}
