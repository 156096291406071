import googleService from "@/web/services/auth/google";

export const state = () => ({
  googleToken: null,
});

export const mutations = {
  setGoogleToken(state, googleToken) {
    state.googleToken = googleToken;
  },

  clearGoogleToken(state) {
    state.googleToken = null;
  },
};

export const actions = {
  async initGoogleSignIn({ commit }) {
    let OnSuccess = googleToken => {
      commit("setGoogleToken", googleToken);
    };
    let OnError = error => {
      commit("clearGoogleToken");
      console.error(error);
    };
    await googleService.init(process.env.VUE_APP_GOOGLE_CLIENT_ID, OnSuccess, OnError);
  },

  async logout({ commit, state }) {
    if (state.googleToken) {
      await googleService.logout();
      commit("clearGoogleToken");
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
