<template>
  <form action="." method="POST" @submit.prevent="submit">
    <!-- Email address field -->
    <div class="field">
      <label class="label has-text-primary">{{ $t("auth.email") }}</label>
      <div class="control">
        <input class="input is-simple" type="text" :placeholder="$t('auth.email')" v-model="email" />
      </div>
      <template v-if="submitted">
        <p v-if="!$v.email.required" class="help is-danger">{{ $t("auth.error_email") }}</p>
        <p v-if="!$v.email.email" class="help is-danger">{{ $t("auth.error_email") }}</p>
      </template>
    </div>

    <!-- Password field -->
    <div class="field mb-3">
      <label class="label has-text-primary">{{ $t("auth.password") }}</label>
      <div class="control is-flex is-flex-direction-row is-align-items-center is-justify-content-center">
        <input class="input is-simple" :type="passwordInputType" :placeholder="$t('auth.password')" v-model="password" />
        <div class="password-visibility-button" @click="togglePasswordVisibility">
          <visibility-off-icon class="svg-icon has-fill-primary" v-if="isPasswordVisible"></visibility-off-icon>
          <visibility-icon class="svg-icon has-fill-primary" v-else></visibility-icon>
        </div>
      </div>
      <template v-if="submitted">
        <p v-if="!$v.password.required" class="help is-danger">{{ $t("auth.error_password") }}</p>
        <p v-if="!$v.password.minLength" class="help is-danger">{{ $t("auth.error_password") }}</p>
      </template>
    </div>

    <!-- Submit button -->
    <div class="field">
      <div class="control">
        <button class="button is-primary is-fullwidth" type="submit" :disabled="showProgress">
          {{ $t("auth.already_have_an_account_substring") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import VisibilityIcon from "@/assets/auth/icon_visibility.svg";
import VisibilityOffIcon from "@/assets/auth/icon_visibility_off.svg";

export default {
  name: "LoginForm",

  props: ["showProgress"],

  components: { VisibilityOffIcon, VisibilityIcon },

  data() {
    return {
      submitted: false,
      email: "",
      password: "",
      isPasswordVisible: false,
    };
  },

  validations: {
    email: {
      required,
      email: value => email(value.toLowerCase().replace(/\s/g, "")),
    },
    password: {
      required,
      minLength: minLength(7),
    },
  },

  filters: {
    json(value) {
      return JSON.stringify(value);
    },
  },

  methods: {
    submit(event) {
      this.$v.$touch();
      this.$data.submitted = true;

      if (!this.$v.$invalid) {
        const { email, password } = this.$data;
        this.$emit("submit", { email: email.toLowerCase().replace(/\s/g, ""), password });
      }
    },

    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },

  computed: {
    passwordInputType() {
      if (this.isPasswordVisible) {
        return "text";
      } else {
        return "password";
      }
    },
  },
};
</script>
