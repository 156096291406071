<template>
  <form action="." method="POST" @submit.prevent="submit">
    <div class="columns is-mobile is-vcentered mb-2" ref="firstNameAndLastNameFields" v-if="isAppleSignIn">
      <div class="column is-half">
        <div class="field">
          <label class="label has-text-primary">{{ $t("auth.auth_firstname_placeholder") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('auth.auth_firstname_placeholder')" v-model="firstName" />
          </div>
          <template v-if="$v.firstName.$error">
            <p v-if="!$v.firstName.required" class="help is-danger">{{ $t("auth.error_first_name") }}</p>
          </template>
        </div>
      </div>
      <div class="column is-half">
        <!-- Last name field -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("auth.auth_lastname_placeholder") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('auth.auth_lastname_placeholder')" v-model="lastName" />
          </div>
          <template v-if="$v.lastName.$error">
            <p v-if="!$v.lastName.required" class="help is-danger">{{ $t("auth.error_last_name") }}</p>
          </template>
        </div>
      </div>
    </div>

    <!-- Privacy policy and terms of service consent -->
    <div class="field">
      <div class="control">
        <label class="checkbox is-size-7">
          <checkbox class="checkbox-input" v-model="terms"></checkbox>
          <i18n path="auth.terms_and_conditions" tag="span">
            <a target="_blank" :href="$t('url.privacy')">{{ $t("auth.privacy_policy_substring") }}</a>
            <a target="_blank" :href="$t('url.terms')">{{ $t("auth.terms_of_service_substring") }}</a>
          </i18n>
        </label>
      </div>
      <template v-if="$v.terms.$error">
        <p v-if="!$v.terms.accept" class="help is-danger">{{ $t("auth.consent_required") }}</p>
      </template>
    </div>

    <!-- GDPR consent -->
    <div class="field mb-3">
      <div class="control">
        <label class="checkbox is-size-7">
          <checkbox class="checkbox-input" v-model="gdpr"></checkbox>
          <i18n path="auth.gdpr_terms" tag="span">
            <a target="_blank" :href="$t('url.gdpr')">
              {{ $t("auth.read_more") }}
            </a>
          </i18n>
        </label>
      </div>
      <template v-if="$v.gdpr.$error">
        <p v-if="!$v.gdpr.accept" class="help is-danger">{{ $t("auth.consent_required") }}</p>
      </template>
    </div>

    <!-- Submit button -->
    <div class="field">
      <div class="control">
        <button class="button is-primary is-fullwidth" type="submit" :disabled="showProgress">
          <slot>{{ $t("auth.log_in") }}</slot>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Checkbox from "@/shared/components/form/Checkbox";
export default {
  name: "ConsentForm",

  props: ["showProgress", "isAppleSignIn"],

  components: { Checkbox },

  data() {
    return {
      terms: false,
      gdpr: false,
      firstName: "",
      lastName: "",
    };
  },

  validations() {
    let validations = {
      terms: { accepted },
      gdpr: { accepted },
    };
    if (this.isAppleSignIn) {
      validations.firstName = { required };
      validations.lastName = { required };
    }
    return validations;
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const { gdpr, terms, firstName, lastName } = this.$data;
        if (this.isAppleSignIn) {
          this.$emit("submit", { gdpr, terms, firstName, lastName });
        } else {
          this.$emit("submit", { gdpr, terms });
        }
      }
    },
  },
};

function accepted(value) {
  return !!value;
}
</script>
