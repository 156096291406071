import { Model } from "@vuex-orm/core";

export default class ComponentModel extends Model {
  static entity = "components";

  static fields() {
    return {
      id: this.attr(null),
      event_id: this.number(-1),
      label: this.string(""),
      order: this.number(-1),
      visible_on_lp: this.boolean(true),
      component_name: this.string(""),
      additional_info: this.attr(null).nullable(),
    };
  }
}
