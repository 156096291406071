<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ $t("email_confirmation.register_title") }}
      </h1>
      <div class="modal-close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <div class="size-6 subtitle has-text-centered">
        {{ $t("email_confirmation.register_description") }}
      </div>
      <div>
        <button @click="resend" :disabled="emailResent || isRequesting" class="button is-fullwidth is-primary mt-1">
          {{ $t("email_confirmation.resend") }}
        </button>
        <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>
        <p v-if="emailResent" class="help is-primary">{{ $t("email_confirmation.resend_success") }}</p>

        <button class="button is-fullwidth is-primary mt-1" style="margin-top: 1rem" @click="goToLoginPage">
          {{ $t("auth.log_in") }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";
import { mapActions, mapMutations, mapState } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import Constants from "@/web/constants";
import { pluckErrorCode } from "@/shared/utils";

export default {
  name: "EmailConfirmationModal",
  mixins: [RequestMixin],

  components: {
    CloseIcon,
  },

  props: {
    email: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      emailResent: false,
    };
  },

  requests: {
    async resend() {
      await this.resendEmailConfirmation({ email: this.email, eventId: this.eventId }).then(result => {
        this.emailResent = true;
      });
    },
  },

  methods: {
    ...mapMutations("auth", ["setEmailTakenModalStatus"]),
    ...mapActions("auth", ["resendEmailConfirmation"]),

    goToLoginPage() {
      this.setEmailTakenModalStatus(true);
      this.$parent.close();
    },
  },

  computed: {
    ...mapState(["eventId"]),

    errorMessage() {
      const errorCode = this.error && pluckErrorCode(this.error);
      if (errorCode === Constants.REST_EMAIL_ACCOUNT_ALREADY_CONFIRMED) {
        return this.$t("email_confirmation.resend_error_account_already_confirmed");
      } else if (errorCode === Constants.REST_EMAIL_ACCOUNT_NOT_FOUND) {
        return this.$t("email_confirmation.resend_error_account_not_found");
      } else {
        return this.error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}
</style>
