import { Model } from "@vuex-orm/core";
import AttachmentModel from "@/web/store/models/AttachmentModel";

export default class UserModel extends Model {
  static entity = "users";

  static primaryKey = "user_identity_token";

  static fields() {
    return {
      user_identity_token: this.attr(null),
      user_external_uuid: this.string(null).nullable(),
      qrcode: this.string(""),
      first_name: this.string(" "),
      last_name: this.string(" "),
      position: this.string(null).nullable(),
      company: this.string(null).nullable(),
      country: this.string(null).nullable(),
      biography: this.string(null).nullable(),
      email: this.string(null).nullable(),
      phone_number: this.string(null).nullable(),
      what_i_offer: this.string(null).nullable(),
      what_i_need: this.string(null).nullable(),
      show_contact_details: this.boolean(false),
      facebook_profile: this.string(null).nullable(),
      twitter_profile: this.string(null).nullable(),
      linked_profile: this.string(null).nullable(),
      youtube_profile: this.string(null).nullable(),
      instagram_profile: this.string(null).nullable(),
      picture_id: this.attr(null),
      picture: this.belongsTo(AttachmentModel, "picture_id"),
      is_friend: this.boolean(false),
      is_attendee: this.attr(false),
    };
  }
}
