import { Database } from "@vuex-orm/core";
import EventModel from "./models/EventModel";
import EventDisplayModel from "./models/EventDisplayModel";
import TicketModel from "./models/TicketModel";
import UserModel from "./models/UserModel";
import UserEventTicketModel from "./models/UserEventTicketModel";
import UserSessionTicketModel from "./models/UserSessionTicketModel";
import ComponentModel from "./models/ComponentModel";
import ExhibitorModel from "./models/ExhibitorModel";
import SpeakerModel from "./models/SpeakerModel";
import AttachmentModel from "./models/AttachmentModel";
import ExhibitorContactPersonModel from "./models/ExhibitorContactPersonModel";
import ExhibitorContactUserModel from "./models/ExhibitorContactUserModel";
import PartnerCategoryModel from "./models/PartnerCategoryModel";
import PartnerModel from "./models/PartnerModel";
import PartnerContactPersonModel from "./models/PartnerContactPersonModel";
import EventTicketModel from "./models/EventTicketModel";
import AgendaSessionModel from "./models/AgendaSessionModel";
import AgendaSessionDisplayModel from "./models/AgendaSessionDisplayModel";
import AgendaSessionPathModel from "./models/AgendaSessionPathModel";
import EventDayModel from "./models/EventDayModel";
import AgendaSessionSpeaker from "./models/AgendaSessionSpeaker";
import InboxThreadModel from "./models/InboxThreadModel";
import InboxMessageModel from "./models/InboxMessageModel";
import FriendInvitationModel from "./models/FriendInvitationModel";
import NotificationModel from "./models/NotificationModel";
import QuizModel from "./models/QuizModel";
import QuizQuestionModel from "./models/QuizQuestionModel";
import QuizAnswerModel from "./models/QuizAnswerModel";
import QuizResultModel from "./models/QuizResultModel";
import FeedWallChannelModel from "./models/FeedWallChannelModel";
import FeedWallThreadModel from "./models/FeedWallThreadModel";
import FeedWallCommentModel from "./models/FeedWallCommentModel";
import AgendaSessionQuestionModel from "./models/AgendaSessionQuestionModel";
import ExhibitorModeratorModel from "./models/ExhibitorModeratorModel";
import SpeakerModeratorModel from "@/web/store/models/SpeakerModeratorModel";
import ExhibitorUpdateModel from "@/web/store/models/ExhibitorUpdateModel";
import ExhibitorUpdateTranslationModel from "@/web/store/models/ExhibitorUpdateTranslationModel";
import ExhibitorUpdateContactPersonModel from "@/web/store/models/ExhibitorUpdateContactPersonModel";
import ExhibitorUpdateContactPersonTranslationModel from "@/web/store/models/ExhibitorUpdateContactPersonTranslationModel";
import ExhibitorTagModel from "@/web/store/models/ExhibitorTagModel";
import EventCustomFormModel from "@/web/store/models/EventCustomFormModel";
import EventTicketBulkDiscountModel from "@/web/store/models/EventTicketBulkDiscountModel";
import EventAddonModel from "@/web/store/models/EventAddonModel";
import BusinessMatchingSessionModel from "@/web/store/models/BusinessMatchingSessionModel";
import BusinessMatchingMeetingModel from "@/web/store/models/BusinessMatchingMeetingModel";
import BusinessMatchingFilterGroup from "@/web/store/models/BusinessMatchingFilterGroup";
import BusinessMatchingFilterTag from "@/web/store/models/BusinessMatchingFilterTag";
import UserUnavailabilityModel from "@/web/store/models/UserAvailabilityModel";

const database = new Database();

database.register(EventModel);
database.register(EventDisplayModel);
database.register(TicketModel);
database.register(UserModel);
database.register(UserEventTicketModel);
database.register(UserSessionTicketModel);
database.register(ComponentModel);
database.register(ExhibitorContactUserModel);
database.register(ExhibitorModel);
database.register(ExhibitorTagModel);
database.register(ExhibitorContactPersonModel);
database.register(AttachmentModel);
database.register(PartnerCategoryModel);
database.register(PartnerModel);
database.register(PartnerContactPersonModel);
database.register(EventTicketModel);
database.register(EventTicketBulkDiscountModel);
database.register(EventAddonModel);
database.register(SpeakerModel);
database.register(AgendaSessionModel);
database.register(AgendaSessionDisplayModel);
database.register(AgendaSessionPathModel);
database.register(EventDayModel);
database.register(EventCustomFormModel);
database.register(AgendaSessionSpeaker);
database.register(InboxThreadModel);
database.register(InboxMessageModel);
database.register(FriendInvitationModel);
database.register(NotificationModel);
database.register(QuizModel);
database.register(QuizQuestionModel);
database.register(QuizAnswerModel);
database.register(QuizResultModel);
database.register(FeedWallChannelModel);
database.register(FeedWallThreadModel);
database.register(FeedWallCommentModel);
database.register(AgendaSessionQuestionModel);
database.register(BusinessMatchingSessionModel);
database.register(BusinessMatchingMeetingModel);
database.register(BusinessMatchingFilterGroup);
database.register(BusinessMatchingFilterTag);
database.register(UserUnavailabilityModel);
database.register(ExhibitorModeratorModel);
database.register(ExhibitorUpdateModel);
database.register(ExhibitorUpdateTranslationModel);
database.register(ExhibitorUpdateContactPersonModel);
database.register(ExhibitorUpdateContactPersonTranslationModel);
database.register(SpeakerModeratorModel);

export default database;
