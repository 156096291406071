<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <p class="modal-card-title">
        {{ title }}
      </p>
      <p class="modal-card-subtitle">
        {{ subtitle }}
      </p>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <custom-register-form
        :form-data="customRegisterFormData"
        :include-register-form="false"
        :current-locale="currentLocale"
        @submit="onFormSubmit"
      ></custom-register-form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CustomRegisterForm from "@/web/components/forms/CustomRegisterForm";
import RequestMixin from "@/shared/mixins/RequestMixin";
import Constants from "@/web/constants";
import RedirectMixin from "@/web/mixins/RedirectMixin";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

export default {
  name: "UserSpecificFieldsModal",
  components: { CustomRegisterForm },
  mixins: [RequestMixin, RedirectMixin],

  beforeMount() {
    this.applyFormTranslations();
  },

  computed: {
    ...mapGetters("locales", ["currentLocale"]),
    ...mapGetters(["customRegisterFormData"]),

    title() {
      return this.$t("auth.fill_specific_fields_modal_title");
    },

    subtitle() {
      return this.$t("auth.fill_specific_fields_modal_subtitle");
    },
  },

  methods: {
    ...mapActions(["submitAdditionalData"]),
    ...mapMutations("auth", ["setAuthLoadingStatus"]),

    onFormSubmit({ credentials, metaData, profileData }) {
      this.submit(metaData, profileData);
    },

    openAuthLoadingModal() {
      this.$root.openAuthLoadingModal();
    },

    openLoginModal() {
      this.$root.openLoginModal(this.$props);
      this.$parent.close();
    },

    afterRegister() {
      const defaultRoute = { name: Constants.ROUTE_MAIN_INTRODUCTION };
      this.nextRedirect(defaultRoute);
      this.$parent.close();
    },

    applyFormTranslations() {
      if (this.customRegisterFormData && this.customRegisterFormData[FormBuilderConstants.TRANSLATIONS]) {
        this.$mergeCustomTranslations(
          this.customRegisterFormData[FormBuilderConstants.TRANSLATIONS],
          this.customRegisterFormData[FormBuilderConstants.TRANSLATIONS_NAMESPACE]
        );
      }
    },
  },

  requests: {
    async submit(metaData, profileData) {
      this.openAuthLoadingModal();
      await this.submitAdditionalData({ metaData: metaData, profileData: profileData });
      this.afterRegister();
    },
  },

  watch: {
    error(newVal) {
      if (newVal) {
        this.setAuthLoadingStatus(Constants.AUTH_LOADING_STATUSES.ERROR);
        const data = newVal.response.data;
        if (data && data.errors && Array.isArray(data.errors)) {
          this.$root.openErrorModal(newVal);
        }
        this.error = null;
      }
    },
  },
};
</script>

<style scoped></style>
